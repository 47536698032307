/**
Copyright (C) 2022 by SeQuenX  - All Rights Reserved
This file is part of the ComplyVantage product development,
and is released under the "Commercial License Agreement".
You should have received a copy of the Commercial License Agreement license with
this file. If not, please write to: legal@sequenx.com, or visit www.sequenx.com
*/
import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  Shurjeel,
  Saira,
  Zier,
  Aarti,
  jagmeet,
  Murali,
  Shahmir,
  Almas,
  Arsalan,
  Uzair,
  Sheetal,
  chandana,
  Jawad,
  Kamran,
  usama,
  Fazle,
  Raiha,
  Rajesh,
  sukanya,
  zakwan,
  UzairAhmed,
  farhat,
  sri,
  Tehsham,
  aryan,
} from "../assets/images";
import { TeamDetailsDialogs } from ".";

const TeamData = [
  {
    name: "Shurjeel Tousif",
    designation: "Founder/CEO",
    description: ".",
    icon: Shurjeel,
    hover: false,
  },
  {
    name: "Saira Naim",
    designation: "Operations Director",
    description: ".",
    icon: Saira,
    hover: false,
  },
  {
    name: "Aarti Varshney",
    designation: "Director, Recruitment & HR",
    description: ".",
    icon: Aarti,
    hover: false,
  },
  {
    name: "Rajesh Lakkarsu",
    designation: "Director, APAC region",
    description: ".",
    icon: Rajesh,
    hover: false,
  },
  {
    name: "Zier den Heijer",
    designation: "Technical Architect",
    description: ".",
    icon: Zier,
    hover: false,
  },
  {
    name: "Jagmeet Singh",
    designation: "Senior Consultant",
    description: ".",
    icon: jagmeet,
    hover: false,
  },
  {
    name: "Sri Harsha Katwa",
    designation: "Senior Consultant Project Management",
    description: ".",
    icon: sri,
    hover: false,
  },
  {
    name: "Tehsham Khan",
    designation: "Project Partner / Business Development",
    description: ".",
    icon: Tehsham,
    hover: false,
  },
  {
    name: "Murali Krishna",
    designation: "Software Engineering & Services Manager",
    description: ".",
    icon: Murali,
    hover: false,
  },
  {
    name: "Shahmeer Khan",
    designation: "Software Engineer (Team Lead)",
    description: ".",
    icon: Shahmir,
    hover: false,
  },
  {
    name: "Muhammad Arsalan",
    designation: "Software Engineer (Frontend)",
    description: ".",
    icon: Arsalan,
    hover: false,
  },
  {
    name: "Almas Shabbir",
    designation: "Software Engineer (Backend)",
    description: ".",
    icon: Almas,
    hover: false,
  },

  {
    name: "Uzair Khan",
    designation: "Software Engineer (Backend)",
    description: ".",
    icon: Uzair,
    hover: false,
  },
  {
    name: "Sukanya Nallapa Reddy",
    designation: "Java developer",
    description: ".",
    icon: sukanya,
    hover: false,
  },
  {
    name: "Muhammad Kamran",
    designation: "DevOps Engineer",
    description: ".",
    icon: Kamran,
    hover: false,
  },
  {
    name: "Usama Shabbir",
    designation: "Software Engineer (Frontend)",
    description: ".",
    icon: usama,
    hover: false,
  },
  {
    name: "Aryan Verma",
    designation: "Consultant, Data & AI",
    description: ".",
    icon: aryan,
    hover: false,
  },
  {
    name: "Farhat Ul Ain",
    designation: "Junior Backend Developer",
    description: ".",
    icon: farhat,
    hover: false,
  },
  {
    name: "Uzair Ahmed",
    designation: "Software Engineer (Backend)",
    description: ".",
    icon: UzairAhmed,
    hover: false,
  },
  {
    name: "Chandana Rao",
    designation: "Open Source Competence Lead",
    description: ".",
    icon: chandana,
    hover: false,
  },
  {
    name: "Sheetal Deshpande",
    designation: "Lead Consultant, SQA & OSS Audit",
    description: ".",
    icon: Sheetal,
    hover: false,
  },
  {
    name: "Jawad Khan",
    designation: "SQA Engineer",
    description: ".",
    icon: Jawad,
    hover: false,
  },
  {
    name: "Raiha Narmeen",
    designation: "SQA Engineer",
    description: ".",
    icon: Raiha,
    hover: false,
  },
  {
    name: "Fazle Yazdan",
    designation: "SQA Engineer",
    description: ".",
    icon: Fazle,
    hover: false,
  },
  {
    name: "Zakwan Zaib",
    designation: "Junior SQA Engineer",
    description: ".",
    icon: zakwan,
    hover: false,
  },
  // Other service items...
];

const TeamDetails = () => {
  const [team, setTeam] = useState(TeamData);
  const { t } = useTranslation();

  const handleMouseEnter = (index) => {
    setTeam((prevTeam) => {
      const updatedTeam = [...prevTeam];
      updatedTeam[index].hover = true;
      return updatedTeam;
    });
  };

  const handleMouseLeave = (index) => {
    setTeam((prevTeam) => {
      const updatedTeam = [...prevTeam];
      updatedTeam[index].hover = false;
      return updatedTeam;
    });
  };
  return (
    <div className="bg-[#455590] py-4 px-4 text-center text-white">
      <div className="container mx-auto flex flex-col items-center justify-center md:flex-row">
        <div className="w-full text-justify">
          <h1 className="text-3xl font-bold font-['Alegreya_Sans_SC']">
            {t("team.subTitle")}
          </h1>
          <p className=" my-4">{t("team.description")}</p>
        </div>
      </div>
      <div className="container mx-auto flex flex-col items-center justify-center md:flex-row">
        <Grid container spacing={0} justifyContent="left">
          {team.map((service, index) => (
            <Grid key={index} item xs={12} md={6} lg={4} align="left">
              <div className="flex flex-col h-full">
                <div
                  className="service-icon relative flex justify-center items-center p-4 cursor-pointer"
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={() => handleMouseLeave(index)}
                >
                  <img
                    src={service?.icon}
                    className="md:h-[20rem] h-[12rem] rounded-lg"
                    alt=""
                  />
                  {service.hover && (
                    <div className="absolute md:px-[7.4rem] px-[3.2rem] md:py-[9.25rem] py-[5rem] flex justify-center items-center bg-black bg-opacity-40 rounded-lg transition duration-300 ease-in-out transform cursor-pointer">
                      <p className="text-white font-['Alegreya_Sans_SC']">
                        <TeamDetailsDialogs details={service} />
                      </p>
                    </div>
                  )}
                </div>
                <h3 className="text-[1.8rem] text-center font-['Alegreya_Sans_SC'] my-2 mx-1">
                  {service?.name}
                </h3>
                <p className="text-center mx-2 mb-2">{service?.designation}</p>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default TeamDetails;
