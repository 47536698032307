/**
Copyright (C) 2022 by SeQuenX  - All Rights Reserved
This file is part of the ComplyVantage product development,
and is released under the "Commercial License Agreement".
You should have received a copy of the Commercial License Agreement license with
this file. If not, please write to: legal@sequenx.com, or visit www.sequenx.com
*/
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import SeQuenXLogo from "../assets/svg/SeQuenXLogo.svg";
import UKLogo from "../assets/svg/united-kingdom.svg";
import NLLogo from "../assets/svg/netherlands.svg";
import "./Navbar.css";
import i18n from "./../utils/i18n";
import { useTranslation } from "react-i18next";

const supportedLanguages = ["en", "nl"]; // Define your supported languages here

const Navbar = () => {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  // const [dropdown1, setDropdown1] = useState(false);
  const location = useLocation(); // Get the current location object
  const [langStatus, setLangStatus] = useState("en");
  const { t } = useTranslation();

  useEffect(() => {
    const temp = sessionStorage.getItem("language");
    if (temp === null || temp === undefined) {
      sessionStorage.setItem("language", "en");
    }
    setLangStatus(temp);
  }, []);

  useEffect(() => {
    // Update the active state based on the current URL pathname
    const pathname = location.pathname;
    const activeItems = document.querySelectorAll(".nav-item");

    // Remove "active" class from all menu items
    activeItems.forEach((item) => {
      item.classList.remove("active");
    });

    // Add "active" class to the menu item with a matching pathname
    activeItems.forEach((item) => {
      if (item.firstChild.getAttribute("href") === pathname) {
        item.classList.add("active");
      }
    });
  }, [location.pathname]);

  const handleClick = () => {
    setClick(!click);
  };

  const handleDropdown = () => {
    if (window.innerWidth <= 940) {
      setDropdown(!dropdown);
    }
  };
  // const handleDropdown1 = () => {
  //   if (window.innerWidth <= 940) {
  //     setDropdown1(!dropdown1);
  //   }
  // };
  // Function to set the language in a cookie and update I18n
  const setLanguageInCookie = (language) => {
    if (
      supportedLanguages.includes(language) &&
      sessionStorage.getItem("language") !== language
    ) {
      sessionStorage.setItem("language", language);
      i18n.changeLanguage(language);
      setLangStatus(sessionStorage.getItem("language"));
    }
  };

  return (
    <div className="bg-white shadow-sm sticky top-0 z-[100]">
      <div className="navbar container mx-auto">
        <div className="logo cursor-pointer">
          <a href="/">
            <img src={SeQuenXLogo} alt="logo" className="w-[70%]" />
          </a>
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className={`nav-item`}>
            <a href="/about-us">{t("header.aboutus")}</a>
          </li>
          <li
            className={`nav-item ${dropdown ? "dropdown active" : "dropdown"}`}
          >
            <span
              className="dropdown-toggle cursor-pointer"
              onClick={handleDropdown}
            >
              {t("header.services")}
            </span>
            <ul className={`dropdown-menu ${dropdown ? "active" : ""}`}>
              <li>
                <a href="/service/software-dev">{t("header.service1")}</a>
              </li>
              <li>
                <a href="/service/software-quality-assurance">
                  {t("header.service2")}
                </a>
              </li>
              <li>
                <a href="/service/oss-compliance-and-security">
                  {" "}
                  {t("header.service3")}
                </a>
              </li>
              <li>
                <a href="/service/software-consultacy-and-training">
                  {t("header.service4")}
                </a>
              </li>
            </ul>
          </li>
          <li className={`nav-item`}>
            <a href="/team"> {t("header.team")}</a>
          </li>
          <li className={`nav-item`}>
            <a href="/careers"> {t("header.careers")}</a>
          </li>
          {/* <li
            className={`nav-item ${dropdown1 ? "dropdown active" : "dropdown"}`}
          >
            <span
              className="dropdown-toggle cursor-pointer"
              onClick={handleDropdown1}
            >
              Products
            </span>
            <ul className={`dropdown-menu ${dropdown1 ? "active" : ""}`}>
              <li>
                <a href="/products/comply-vantage">ComplyVantage</a>
              </li>
            </ul>
          </li> */}

          <li className={`nav-item mr-4`}>
            <a href="/contact-us"> {t("header.contact")}</a>
          </li>
          {/* <li className={`nav-item`}>
            <a href="/my-sequenx">My SeQuenX</a>
          </li> */}
          <li className={`language nav-item`}>
            <button onClick={() => setLanguageInCookie("nl")}>
              <img src={NLLogo} alt="logo" />
              <span className={` ${langStatus === "nl" ? "text-red-800" : ""}`}>
                NL
              </span>
            </button>
            <span className="spanLine">|</span>
          </li>
          <li className={`language nav-item ml-[-1rem]`}>
            <button onClick={() => setLanguageInCookie("en")}>
              <img src={UKLogo} alt="logo" />
              <span className={` ${langStatus === "en" ? "text-red-800" : ""}`}>
                EN
              </span>
            </button>
          </li>

          {/* <li className={`language nav-item`}>
            {langStatus === "en" ? (
              <button onClick={() => setLanguageInCookie("nl")}>
                <img src={NLLogo} alt="logo" />
                <span
                  className={` ${langStatus === "nl" ? "text-red-800" : ""}`}
                >
                  NL
                </span>
              </button>
            ) : (
              <button onClick={() => setLanguageInCookie("en")}>
                <img src={UKLogo} alt="logo" />
                <span
                  className={` ${langStatus === "en" ? "text-red-800" : ""}`}
                >
                  EN
                </span>
              </button>
            )}
          </li> */}
        </ul>
        <div className="hamburger" onClick={handleClick}>
          {click ? (
            <FaTimes size={30} style={{ color: "#000000" }} />
          ) : (
            <FaBars size={30} style={{ color: "#000000" }} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
